const region = 'eu-central-1';
const userPoolId = 'eu-central-1_gVBBwRZkg';
const userPoolWebClientId = '45qhq678s7jui25l8olq1j9ki7';
const identityPoolId = 'eu-central-1:3ef9768c-0175-425f-a556-8c07f477b743';

export const environment = {
  production: false,
  apiUrl: 'https://api.demo-partner-flex.powerdata.de/il-backend-mock',
  AWS: {
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'en',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
