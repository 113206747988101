import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PpManagerService {

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private apiService: ApiService,
  ) { }
  
  getActivatedComponents(): Observable<any> {
    return this.apiService.get('activatedComponents');
  }

  getAnalyticsData(): Observable<any> {
    return this.apiService.get('analytics');
  }

  getDashboardData(option?: string): Observable<any> {
    return this.apiService.get('dashboard');
  }

  getConfigurationData(): Observable<any> {
    return this.apiService.get('configurations')
  }

  getConfigurationDetailData(component: string): Observable<any> {
    return this.apiService.get(`configuration/${component}`);
  }

  getDashboardDetailData(component: string): Observable<any> {
    return this.apiService.get(`dashboard/${component}`);
  }

  getComponents(): Observable<any> {
    return this.apiService.get(`components`);
  }

  getComponentsData(component: string): Observable<any> {
    return this.apiService.get(`components/${component}`);
  }

}
