import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IApiResponseHeader } from '@models/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public errorMessage: any;
  private options: IApiResponseHeader = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': `*`
    })
  };

  constructor(private httpClient: HttpClient) { }

  /**
   * Getting some data
   * @param path
   * @param params
   * @returns GET request body
   */
  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(`/${path}`, { params });
  }

  /**
   * Creating or posting some data
   * @param path
   * @param body
   * @returns POST request body
   */
  public post(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(`/${path}`, JSON.stringify(body), this.options)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Updating data
   * @param path
   * @param body
   * @returns PUT request body
   */
  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(`/${path}`, JSON.stringify(body), this.options);
  }

  /**
   * Deleting data
   * @param path
   * @returns DELETE request body
   */
  public delete(path: string): Observable<any> {
    return this.httpClient.delete(`/${path}`);
  }

  /**
   * Handling Errors
   * @param error
   * @returns error
   */
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
